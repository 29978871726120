import React from 'react';
import './flippable-card.css';
import Adult from './Adult';
import {CSSTransition} from 'react-transition-group';
import {useState} from 'react';
import Kids from "./Kids"
import Corporate from "./Corporate"

function FlippableCard() {
    const [showFront, setShowFront] = useState(true);
    const [showFront1, setShowFront1] = useState(true);
    const [showFront2, setShowFront2] = useState(true);
    return(
        <div style={{alignItems:"center",display:"flex",flexDirection:"column",paddingTop:"2.5%"}} id="services" > 
        <h1 style={{ fontWeight: "bold",color:"#CE7777" }}>Our Services</h1>
        <div className="flippable-card-container" style={{width:"100%"}}>
            <CSSTransition
                in={showFront}
                timeout={30000}
                classNames='flip'
            >
                <Adult onClick={() => {
                    setShowFront((v) => !v);
                }}/>
            </CSSTransition>
        </div>
        <div className="flippable-card-container" style={{backgroundColor:"#FAF4F4", width:"100%"}}>
            <CSSTransition
                in={showFront1}
                timeout={30000}
                classNames='flip'
            >
                <Kids onClick={() => {
                    setShowFront1((v) => !v);
                }}/>
            </CSSTransition>
        </div>
        <div className="flippable-card-container"  style={{width:"100%"}}>
            <CSSTransition
                in={showFront2}
                timeout={30000}
                classNames='flip'
            >
                <Corporate onClick={() => {
                    setShowFront2((v) => !v);
                }}/>
            </CSSTransition>
        </div>
        </div>
    );
}

export default FlippableCard;