import Button from 'react-bootstrap/Button';
import React from "react";
import "./Adult.css"
import "./fliptransition.css";
import veeyoga_2 from "./Corporate.jpg";
function Adult({onClick}) {
return(
     <div className="adult" >
     <div className="front">
        <div className= "front1a">
            <img src={veeyoga_2} alt="Not Available"/>
            <Button className="d-flex px-6  front1abutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777",float:"right"}} size="lg" onClick={onClick}>Know More</Button>
        </div>
        <div className= "front1b">
        <h3 className='front1bh3'>Yoga For Corporate</h3>
        <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Elevate your workplace wellness with our Corporate Yoga content. Our tailored sessions reduce stress, enhance focus, and improve physical and mental well-being for your employees. Immerse your team in a journey of self-discovery, rejuvenation, and holistic health through the practice of yoga. Create a positive work culture and watch productivity soar. Invest in their well-being today!</h5>
        <Button className="d-flex px-6 frontbutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777",float:"right"}} size="lg" onClick={onClick}>Know More</Button>
        </div>

     </div>

     <div className="back">
        
        <div className='back1' >
        <h3 className='front1bh3' style={{paddingBottom:"1%",color:"#CE7777",fontWeight:"bold"}}>Benefits Of Yoga</h3>
        <ul>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Stress reduction: Corporate yoga helps employees manage stress and find inner calm amidst work pressures.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Increased focus and productivity: Yoga techniques enhance mental clarity, concentration, and overall productivity in the workplace.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Physical well-being: Regular yoga practice improves flexibility, reduces muscle tension, and promotes better physical health for employees.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Work-life balance: Incorporating yoga into the corporate setting encourages employees to prioritize self-care and achieve a healthy balance between work and personal life.</h5>
            </li>
        </ul>
        
<Button className="d-flex px-6  frontbutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777"}} size="lg" onClick={onClick}>Go Back</Button>
      </div>
     </div>
     </div>
)
}
export default Adult;