import React from 'react'
import "./Feedback.css"
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import swati from './Swati.jpg'
import Jyotsana from './Jyotsna.jpg'
import Aruna from './Aruna.jpg'
import Beenu from './Beenu.jpg'
function Feedback() {
  return (
    <div className='feedback' id="feedback">
      <h1 style={{ fontWeight: "bold", paddingBottom: "1.5%", color: "#CE7777" }}>Testimonials</h1>
      <h4 className='feedbackh4' style={{ color: "#4E413B", fontWeight: "bold", paddingBottom: "2.5%" }}>We are the best , don’t trust what we say , listen to what our clients have to say.</h4>
      <Carousel variant='dark' className='feedback1'>
        <Carousel.Item className='item'>

          <h6 className='testimonial' style={{ lineHeight: "1.75", color: "#4E413B", marginBottom: "0" }}><FontAwesomeIcon icon={faQuoteLeft} style={{ color: "#4E413B", paddingRight: "1.5%" }} />Veenu is an excellent yoga coach. Her classes are a blend of yoga and meditation which not only helps with weight loss but overall health and mental wellness. She is an absolute gem whoso knowledge is amazing. She has really helped me after my pregnancy to get the shape back and also helped in improving my overall posture. It’s been magical to work with her. Cannot recommend her enough!!.Thanks a lot Veenu for your continuous support and hard work with everyone.<FontAwesomeIcon icon={faQuoteRight} style={{ color: "#4E413B", paddingLeft: "1.5%" }} /></h6>
          <Carousel.Caption style={{ padding: "0" }}>
          <img className='test_image' src={Jyotsana} alt='no'/>
            <h6 className='testimonial1' style={{ color: "#4E413B" }}>-Jyotsana Singh</h6>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='item'>
          <h6 className='testimonial' style={{ lineHeight: "1.75", color: "#4E413B" }}><FontAwesomeIcon icon={faQuoteLeft} style={{ color: "#4E413B", paddingRight: "1.5%" }} />I enrolled in Veeyoga's offline classes and my experience has been wonderful so far. Really enjoying doing yoga under Veenu ji's guidance. She is really a great instructor who designs her classes so thoughtfully and makes the whole experience so calm and relaxing catering to individual's need. She teaches with patience and explains the significance of asanas while instructing. Her class is an amalgamation of breathing exercises, asanas, stretches, cardio and meditation which caters to both mental and physical healing.<FontAwesomeIcon icon={faQuoteRight} style={{ color: "#4E413B", paddingLeft: "1.5%" }} /></h6>
          <Carousel.Caption style={{ padding: "0" }}>
          <img className='test_image' src={swati} alt='no'/>
            <h6 className='testimonial1' style={{ color: "#4E413B" }}>-Swati Srivastava</h6>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='item'>
          <h6 className='testimonial' style={{ lineHeight: "1.75", color: "#4E413B" }}><FontAwesomeIcon icon={faQuoteLeft} style={{ color: "#4E413B", paddingRight: "1.5%" }} />During the last couple of years, Covid has caused lots of disruptions in our lives. It impacted us both physically and mentally.It was then I came across Veeyoga- An online, yoga and wellness coaching. I had my apprehensions as to how an online class will work and how effective will it be, but thought of giving it a try. As I started the classes, most of my apprehensions vanished and I found it to be so effective. Thank You Veenu for bringing me back in shape and making me more stronger both mentally and physically.<FontAwesomeIcon icon={faQuoteRight} style={{ color: "#4E413B", paddingLeft: "1.5%" }} /></h6>
          <Carousel.Caption style={{ padding: "0" }}>
          <img className='test_image' src={Aruna} alt='no'/>
            <h6 className='testimonial1' style={{  color: "#4E413B" }}>-Aruna Chauhan</h6>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='item'>
          <h6 className='testimonial' style={{ lineHeight: "1.75", color: "#4E413B" }}><FontAwesomeIcon icon={faQuoteLeft} style={{ color: "#4E413B", paddingRight: "1.5%" }} />Amazing experience with Veenu, she is one of the best yoga teachers I have seen. Me and my son, both are students of Veenu, and have seen big transformation not only in our body but also in overall discipline, and mental strength. In terms of kids yoga, no one can even come closer to her. Kids love her a lot, and the way she gives them weekly/monthly tasks and targets is amazing. Kids really strive for the same. Thanks a lot Veenu for your continuous support and hard work with everyone.<FontAwesomeIcon icon={faQuoteRight} style={{ color: "#4E413B", paddingLeft: "1.5%" }} /></h6>
          <Carousel.Caption style={{ padding: "0" }}>
          <img className='test_image' src={Beenu} alt='no'/>
            <h6 className='testimonial1' style={{  color: "#4E413B" }}>-Beenu Agarwal</h6>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default Feedback
