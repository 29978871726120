import React from "react";
import "./About.css"
import veeyoga_1 from "./Veeyoga-1.jpg"
import Card from 'react-bootstrap/Card';
import veeyoga_3 from "./Veeyoga-3.jpeg";
import Kapil from './Kapil.jpg'
import Rajkumar from './Rajkumar.jpg'
import Shashwat from './Shashwat.jpg'
import Carousel from 'react-bootstrap/Carousel';
function About() {
  return (
    <div id="about">
      <div className="section1">
        <h1 style={{ fontWeight: "bold",color:"#CE7777" }}>About Us</h1>

      </div>
      <div className="section2">
        <div className="section2a">
          <h3 style={{ paddingBottom: "2.5%", color: "#4E413B",fontWeight:"bold" }}>Why We Started</h3>
          <h5 style={{ textAlign: "justify", lineHeight: "1.75", color: "#4E413B" }}>Yoga can treat one on all dimensions from the very moment we start practicing and not just physically infact on every aspect of being (emotionally, mentally, spiritually).<br />
            This is how my life turns to Yoga, its time for you too to make Yoga part of your being which could keep you healthy in complete sense and let you see life as it is.</h5>
        </div>
        <div style={{ textAlign: "right", width: "100%" }}>
          <img src={veeyoga_1} style={{ width: "100%" }} alt="No" />
        </div>
      </div>
      <div className="section3">
        <div >
          <h2 style={{ paddingBottom: "1.5%", fontWeight: "bold", paddingTop: "2.5%",color:"#CE7777" }}>Meet Our Instructor</h2>
        </div>
        <div className="section4">
          <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={veeyoga_3} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Veenu Rajput</Card.Title>
              <Card.Text>
              With 8+ years of teaching, she have expertise in various yoga styles, including Vinyasa, Hath and Yin. She guides students towards inner peace and physical wellness throught mindful movement.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={Kapil} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Kapil Sharma</Card.Title>
              <Card.Text>
              With over 3+ years of experience, he is energetic and compassionate teacher, skillfully leading classes in Ashtanga yoga, empowering students to reach their full potential.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={Rajkumar} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Raj Kumar</Card.Title>
              <Card.Text>
              With over a 7+ years of experience, Raj is a highly skilled and compassionate yoga instructor. His classes focus on promoting  physical strength, flexibility, and mindfulness.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={Shashwat} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Shashwat Saxena</Card.Title>
              <Card.Text>
              Shashwat, a skilled yoga instructor with 2+ years of experience, join him on a transformative journey of self-discovery. He creates a balanced practice that nutures both body and mind.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="section5">
        <Carousel variant='dark'>
        <Carousel.Item >
        <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={veeyoga_3} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Veenu Rajput</Card.Title>
              <Card.Text>
                Experience - 8+ Years
              </Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item >
        <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={Kapil} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Kapil Sharma</Card.Title>
              <Card.Text>
              Experience - 3+ Years
              </Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item >
        <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={Rajkumar} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Raj Kumar</Card.Title>
              <Card.Text>
              Experience - 7+ Years
              </Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item >
        <Card style={{ width: '20rem', backgroundColor: "#FAF4F4", border: "0", alignItems: "center" }}>
            <Card.Img variant="top" src={Rajkumar} style={{ borderRadius: "50%", height: "250px", width: "250px" }} />
            <Card.Body>
              <Card.Title>Shashwat Saxena</Card.Title>
              <Card.Text>
              Experience - 2+ Years
              </Card.Text>
            </Card.Body>
            </Card>
        </Carousel.Item>
        </Carousel>

        </div>
      </div>
    </div>
  )

}
export default About