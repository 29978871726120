import Button from 'react-bootstrap/Button';
import React from "react";
import "./Adult.css"
import "./fliptransition.css";
import veeyoga_2 from "./Veeyoga-2.jpg";
function Adult({onClick}) {
return(
     <div className="adult" >
     <div className="front">
        <div className= "front1a">
            <img src={veeyoga_2} alt="Not Available"/>
            <Button className="d-flex px-6  front1abutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777",float:"right"}} size="lg" onClick={onClick}>Know More</Button>
        </div>
        <div className= "front1b">
        <h3 className='front1bh3'>Yoga For Adults</h3>
        <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Discover the transformative practice of yoga for adults and unlock a world of wellness and inner balance. Explore a wide range of poses, breathing techniques, and meditation practices tailored specifically for adults. Embrace the benefits of increased flexibility, strength, and relaxation as you cultivate a deeper mind-body connection.</h5>
        <Button className="d-flex px-6 frontbutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777",float:"right"}} size="lg" onClick={onClick}>Know More</Button>
        </div>

     </div>

     <div className="back">
        
        <div className='back1' >
        <h3 className='front1bh3' style={{paddingBottom:"1%",color:"#CE7777",fontWeight:"bold"}}>Benefits Of Yoga</h3>
        <ul>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Increased flexibility: Enhance your body's range of motion and posture through yoga. </h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Stress relief: Find tranquility and inner calm while reducing stress levels through yoga practice.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Strength building: Build strength, tone your muscles, and improve overall physical performance with yoga.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Improved balance and coordination: Enhance stability, body awareness, and coordination skills through yoga poses.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Mental clarity and focus: Attain mental clarity, improved concentration, and mindfulness through yoga.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Better sleep: Promote restful sleep and alleviate insomnia with the relaxation techniques of yoga.</h5>
            </li>
        </ul>
        
<Button className="d-flex px-6  frontbutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777"}} size="lg" onClick={onClick}>Go Back</Button>
      </div>
     </div>
     </div>
)
}
export default Adult;