import React from "react";
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
function Footer() {
    return(
        <div className="footer">
            <div>
           <a style={{color: "rgb(78, 65, 59)"}} href="https://www.facebook.com/veeyoga/"><FontAwesomeIcon className="brand" icon={faFacebook} /></a> 
            <a style={{color: "rgb(78, 65, 59)"}} href="https://www.instagram.com/veeyoga_by_veenu/"><FontAwesomeIcon className="brand" icon={faInstagram}/></a>
            <a style={{color: "rgb(78, 65, 59)"}} href="https://www.google.com/maps/place/Veeyoga-+Best+Yoga+Classes+in+Gaur+City/@28.6179669,77.4224372,17z/data=!4m5!3m4!1s0x390cef4420f16835:0xe7373c899a9c4961!8m2!3d28.6178934!4d77.422598"><FontAwesomeIcon className="brand" icon={faYoutube}/></a>
            <a style={{color: "rgb(78, 65, 59)"}} href="https://www.google.com/maps/place/Veeyoga-+Best+Yoga+Classes+in+Gaur+City/@28.6179669,77.4224372,17z/data=!4m5!3m4!1s0x390cef4420f16835:0xe7373c899a9c4961!8m2!3d28.6178934!4d77.422598"><FontAwesomeIcon className="brand" icon={faGoogle}/></a>
            </div>
            <div>
                <p className="para">@2023 CopyRights Veeyoga</p>
            </div>
        </div>
    )
    
}
export default Footer