import React from "react";
import Button from 'react-bootstrap/Button';
import veeyoga from './Veeyoga-0.png'
import "./header.css"
function Header(){
   return(
        <div className="header" id="header">
            <div className="first">
               <h1 style={{fontWeight:"bold",paddingBottom:"2.5%",color:"#CE7777"}}>Beginning of a new you</h1>
               <h5 style={{textAlign:"justify",lineHeight:"1.5",color:"#4E413B"}}>"Veeyoga" is a unique and captivating name for a yoga studio that conveys a sense of vitality, energy, and mindfulness.</h5>
               <h5 style={{textAlign:"justify",lineHeight:"1.5",color:"#4E413B"}}>A perfect studio to get started with your yoga journey. Yoga is a journey of the self, through the self, to the self. Find your inner calmness with VeeYoga studio..</h5>
               <Button className="desktop" style={{backgroundColor:"#CE7777",borderColor: "#CE7777", marginTop:"1.5rem"}} size="lg">Contact Us</Button>{' '}
            </div>
            <div className="second">
               <img className="image1" src={veeyoga} alt="No"/>
               <Button onClick={() => window.location = 'mailto:veeyogaforlife@gmail.com'} className="mobile" style={{backgroundColor:"#CE7777",borderColor: "#CE7777", marginTop:"1.5rem"}} size="lg" >Contact Us</Button>{' '}
            </div>
        </div>
   );
}
export default Header