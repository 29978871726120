import React from 'react';
import './nav.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.png'
function nav() {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "#FFEEEE", minHeight: "100px" }}>
      <Container>
        <Navbar.Brand href="#header"><img className='logo' src={logo} alt="No logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex mx-auto">
            <Nav.Link href="#header" style={{ fontSize: "x-large", fontWeight: "600", color: "#CE7777" }} className='menu1'>Home</Nav.Link>
            <Nav.Link href="#about" style={{ fontSize: "x-large", fontWeight: "600", color: "#CE7777" }} className='menu1'>About Us</Nav.Link>
            <Nav.Link href="#services" style={{ fontSize: "x-large", fontWeight: "600", color: "#CE7777" }} className='menu1'>Services</Nav.Link>
            <Nav.Link href="#feedback" style={{ fontSize: "x-large", fontWeight: "600", color: "#CE7777" }} className='menu1'>Testimonial</Nav.Link>
            <Nav.Link href="#Contact" style={{ fontSize: "x-large", fontWeight: "600", color: "#CE7777" }} className='menu1'>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}
export default nav