import Button from 'react-bootstrap/Button';
import React from "react";
import './Kids.css'
import "./fliptransition.css";
import veeyoga_2 from "./Kids.jpg";
function Adult({onClick}) {
return(
     <div className="adult" >
     <div className='back backa'  style={{backgroundColor:"#FFF2F2"}}>
        <div >
        <h3 className='front1bh3' style={{paddingBottom:"1%",color:"#CE7777",fontWeight:"bold"}}>Benefits Of Yoga</h3>
        <ul>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Increased flexibility: Enhance your body's range of motion and posture through yoga. </h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Stress relief: Find tranquility and inner calm while reducing stress levels through yoga practice.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Strength building: Build strength, tone your muscles, and improve overall physical performance with yoga.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Improved balance and coordination: Enhance stability, body awareness, and coordination skills through yoga poses.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Mental clarity and focus: Attain mental clarity, improved concentration, and mindfulness through yoga.</h5>
            </li>
            <li>
            <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Better sleep: Promote restful sleep and alleviate insomnia with the relaxation techniques of yoga.</h5>
            </li>
        </ul>
        <Button className="d-flex px-6 frontbutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777",float:"right"}} size="lg" onClick={onClick}>Go Back</Button>
        </div>

     </div>

     <div className="front frontab" style={{backgroundColor:"#FFF2F2"}}>
        
        <div className='back1b'  style={{marginRight:"5rem"}}>
        <h3  className='front1bh3' style={{paddingBottom:"1%",color:"#CE7777",fontWeight:"bold"}}>Yoga For Kids</h3>
        <h5 className="front1bh5" style={{textAlign:"justify",lineHeight:"1.75",color:"#4E413B"}}>Introduce your kids to the world of yoga with our fun and engaging content! Through interactive poses, storytelling, and imagination, our Yoga for Kids program helps develop strength, flexibility, and mindfulness. Join us on this transformative journey to promote physical fitness and emotional well-being in your children. Start practicing today and watch them thrive!</h5>
<Button className="d-flex px-6 frontbutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777"}} size="lg" onClick={onClick}>Know More</Button>
        </div>
        <div className='back1a'>
            <img src={veeyoga_2} style={{height:"90%"}} alt="Not Available"/>
            <Button className="d-flex px-6  front1abutton" style={{backgroundColor:"#CE7777",borderColor: "#CE7777",float:"right"}} size="lg" onClick={onClick}>Know More</Button>
        </div>
     </div>
     </div>
)
}
export default Adult;