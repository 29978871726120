import React from 'react';
import Nav from "./nav";
import Header from './Header'
import About from './About'
import FlippableCard from './Flippable-Card';
import Feedback from './Feedback'
import Contact from './Contact';
import Footer from './Footer'
import ScrollButton from './ScrollButton';
function App() {
  return (
    <>
      <Nav />
      <Header />
      <About />
      <FlippableCard />
      <ScrollButton/>
      <Feedback />
      <Contact />
      <Footer/>
    </>
  );
}

export default App;
