import React from "react";
import './Contact.css'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
function contact() {
    return (
        <div style={{ paddingTop: "2.5%" }} id="Contact">
            <h1 style={{ fontWeight: "bold", color: "#CE7777", textAlign: "center" }}>Contact Us</h1>
            <div className="contact">
                <div className="contact1">
                    <div className="contact1a">
                        <h3 className="contact1ah3">Lets Get In Touch</h3>
                        <h6 className="contact1bh6">We would love to connect with you on your yoga journey! Share your thoughts, questions, or feedback with us. Join our community and receive updates on classes, workshops, and wellness tips. Reach out to us today and let's embark on this mindful path.</h6>
                    </div>
                    <div className="contact1b">
                        <div className="contact1bb">
                            <FontAwesomeIcon className="contacticon" icon={faLocationDot}></FontAwesomeIcon>
                            <h6 className="contact1bh6">Shop no 21 Ground floor, Raksha Addela Mart, Gaur City 2, Greater Noida West, Uttar Pradesh 201009</h6>
                        </div>
                        <div className="contact1bb">
                            <FontAwesomeIcon className="contacticon" icon={faPhone}></FontAwesomeIcon>
                            <h6 className="contact1bh6">+919599230255</h6>
                        </div>
                        <div className="contact1bb">
                            <FontAwesomeIcon className="contacticon" icon={faEnvelope}></FontAwesomeIcon>
                            <h6 className="contact1bh6">veeyogaforlife@gmail.com</h6>
                        </div>
                        <div className="eb">
                        <Button className="d-flex px-6 emailbutton" style={{ backgroundColor: "#CE7777", borderColor: "#CE7777", marginLeft: "6%", marginTop: "2.5%" }} size="lg" onClick={() => window.location = 'mailto:veeyogaforlife@gmail.com'}>Send Email</Button>{' '}
                        </div>    
                    </div>
                </div>
                <div className="contact2">
                    <iframe title="Veeyoga" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.3959327276752!2d77.42040931500868!3d28.617893382423627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef4420f16835%3A0xe7373c899a9c4961!2sVeeyoga-%20Best%20Yoga%20Classes%20in%20Gaur%20City!5e0!3m2!1sen!2sin!4v1680438975818!5m2!1sen!2sin" width="450" height="400" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}
export default contact;